import http from '../http-common'
import { APIBaseFunctions, APIDataSliceObj, defaultAPILimit } from '@/components/Utility/APIBase'

class ForeignPlayersDataService extends APIBaseFunctions {
  private cachedForeignPlayersDataSliceObj: APIDataSliceObj = {
    slicemode: 0,
    start: 0,
    limit: defaultAPILimit,
    page: 0,
    pagesize: 0,
    totalcount: 0
  }

  getAll (sortingOrder = '', dataSliceObj: APIDataSliceObj | null = null, extraParameter = '') {
    const currentDataSliceObj = ForeignPlayersDataService.processDataSliceObj({ cachedServicesDataSliceObj: this.cachedForeignPlayersDataSliceObj }, dataSliceObj)

    if (sortingOrder.length > 0) {
      return http.get(`/ud-spillers?_sort=${sortingOrder}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
    }

    return http.get(`/ud-spillers?_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
  }

  async asyncGetAll (sortingOrder = '', dataSliceObj: APIDataSliceObj | null = null, extraParameter = '') {
    const currentDataSliceObj = ForeignPlayersDataService.processDataSliceObj({ cachedServicesDataSliceObj: this.cachedForeignPlayersDataSliceObj }, dataSliceObj)

    if (sortingOrder.length > 0) {
      return http.get(`/ud-spillers?_sort=${sortingOrder}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
    }

    return http.get(`/ud-spillers?_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
  }

  get (id: string) {
    return http.get(`/ud-spillers/${id}`)
  }

  getCount (extraCountParameter = '') {
    return http.get('/ud-spillers/count' + (extraCountParameter.length > 0 ? `?${extraCountParameter}` : ''))
  }

  create (data: any) {
    return http.post('/ud-spillers', data)
  }

  update (id: string, data: any) {
    return http.put(`/ud-spillers/${id}`, data)
  }

  delete (id: string) {
    return http.delete(`/ud-spillers/${id}`)
  }

  deleteAll () {
    return http.delete('/ud-spillers')
  }

  findByForeignPlayerStatus (status: boolean, sortingOrder = '', dataSliceObj: APIDataSliceObj | null = null, extraParameter = '') {
    let sortingOrderParameter = ''
    const currentDataSliceObj = ForeignPlayersDataService.processDataSliceObj({ cachedServicesDataSliceObj: this.cachedForeignPlayersDataSliceObj }, dataSliceObj)

    if (sortingOrder.length > 0) {
      sortingOrderParameter = `&_sort=${sortingOrder}`
    }

    return http.get(`/ud-spillers?ud_spiller_status=${status}${sortingOrderParameter}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
  }

  findBySearchTerm (searchTerm: string, sortingOrder = '', dataSliceObj: APIDataSliceObj | null = null, extraParameter = '') {
    let retVal
    let sortingOrderParameter = ''
    const currentDataSliceObj = ForeignPlayersDataService.processDataSliceObj({ cachedServicesDataSliceObj: this.cachedForeignPlayersDataSliceObj }, dataSliceObj)
    searchTerm = searchTerm.trim()

    if (sortingOrder.length > 0) {
      sortingOrderParameter = `&_sort=${sortingOrder}`
    }

    if (searchTerm.indexOf('@') >= 0) {
      // Handle  email address in search term
      retVal = http.get(`/ud-spillers?ud_spiller_mail_contains=${searchTerm}${sortingOrderParameter}`)
    } else {
      const searchTermArray = searchTerm.split(' ')

      if (searchTermArray.length > 1) {
        // Do a special search in names for the search term where the first part of the of the search term that is seperated with a space is considered as the first name, and the last part of the search term, that is seperated with a space is considered as the last name.
        retVal = http.get(`/ud-spillers?_where[_or][0][ud_spiller_fornavn_contains]=${searchTerm}&_where[_or][1][ud_spiller_efternavn_contains]=${searchTerm}&_where[_or][2][ud_spiller_land_contains]=${searchTerm}&_where[_or][3][0][ud_spiller_fornavn_contains]=${searchTermArray[0]}&_where[_or][3][1][ud_spiller_efternavn_contains]=${searchTermArray[searchTermArray.length - 1]}${sortingOrderParameter}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
      } else {
        // Do a normal search in names for the search term.
        retVal = http.get(`/ud-spillers?_where[_or][0][ud_spiller_fornavn_contains]=${searchTerm}&_where[_or][1][ud_spiller_efternavn_contains]=${searchTerm}&_where[_or][2][ud_spiller_land_contains]=${searchTerm}${sortingOrderParameter}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
      }
    }

    return retVal
  }
}

export default new ForeignPlayersDataService()
